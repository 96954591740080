import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import { useEffect, useRef } from "react";
let Loading = React.lazy(() => import("./components/partial/Loading"));
const Home = React.lazy(() => import("./components/home/Home"));

const WhyChooseUs = React.lazy(() => import("./components/pages/Why"));
const About = React.lazy(() => import("./components/pages/About"));
const Service = React.lazy(() => import("./components/pages/Service"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Requestdemo = React.lazy(() => import("./components/pages/Requestdemo"));

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/"  element={ <React.Suspense fallback={<Loading />}> <Home /> </React.Suspense> } /> 
        <Route exact path="/key-features-of-adantai-health-solutions" element={ <React.Suspense fallback={<Loading />}> <Home /> </React.Suspense> } />
        <Route exact path="/why-choose-adantai-health-care-solutions" element={ <React.Suspense fallback={<Loading />}> <WhyChooseUs /> </React.Suspense> } />
        <Route exact path="/leading-healthcare-it-company-adantai-about-us" element={ <React.Suspense fallback={<Loading />}> <About /> </React.Suspense> } />
        <Route exact path="/comprehensive-healthcare-services" element={ <React.Suspense fallback={<Loading />}> <Service /> </React.Suspense> } />
        <Route exact path="/adantai-contact-us" element={ <React.Suspense fallback={<Loading />}> <Contact /> </React.Suspense> } />
        <Route exact path="/request-a-demo-ai-in-healthcare-by-adantai" element={ <React.Suspense fallback={<Loading />}> <Requestdemo /> </React.Suspense> } />

        <Route path="/*" element={<Navigate to="/key-features-of-adantai-health-solutions" />} />
      </Routes>
      </BrowserRouter>
  );
}

export default App;
